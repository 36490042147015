import { Controller } from "@hotwired/stimulus";
let debounce = require("lodash/debounce");


console.log("autocomplete");

export default class extends Controller {
  static targets = ["search", "menu"]
  connect() {
    console.log("autocomplete connected");
    this.loading = false;

    this.fetch = debounce(this.fetch, 300).bind(this)
  }
  fetch(element) {
    var search = element.value;

    console.log("do search", search);

    if(this.loading || search.length <= 2) {
      return;
    }

    this.loading = true;
    this.element.classList.add('loading');

    var url = "/search/partial?q=" + search;
    var self = this;

    var args = {
      method: 'GET',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      credentials: 'same-origin'
    };

    fetch(url, args).then(function (response) {
      return response.text();
    }).then(function (html) {
      self.menuTarget.innerHTML = html;
      self.loading = false;
      self.element.classList.remove('loading');
    });
  }
  keypress(event) {
    if(event.keyCode == 13) { // return
      var element = this.searchTarget;
      window.location = "/search?q=" + element.value;
    }
  }
  changed(event) {
    var target = this.searchTarget;
    this.fetch(target);

    if(target.value.length > 2) {
      this.show();
    } else {
      this.hide(event);
    }
  }
  show() {
    this.menuTarget.classList.remove('collapsed');
  }
  hide(event) {
    // console.log("hide", event);

    var self = this;
    if(event.type === "blur") {
      setTimeout(function() {
        self.menuTarget.classList.add('collapsed');
      }, 250)
    } else {
      this.menuTarget.classList.add('collapsed');
    }
  }
}
