// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var options = {
      bounds: defaultBounds,
      types: ['geocode'],
      componentRestrictions: {country: 'NZ'}
    };
    this.autocomplete = new google.maps.places.Autocomplete(
      this.element,
      options
    );
    // Avoid paying for data that you don't need by restricting the set of
    // place fields that are returned to just the address components.
    this.autocomplete.setFields(["address_component"]);
    // When the user selects an address from the drop-down, populate the
    // address fields in the form.
    this.autocomplete.setBounds();
    this.autocomplete.addListener("place_changed", this.fillInAddress.bind(this));
  }
  fillInAddress() {
    // Get the place details from the autocomplete object.
    const place = this.autocomplete.getPlace();
    console.log("place", place);
  }
}
