import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [
        "onAccount",
        "onAccountInput",
        "onetap",
        "xero",
        "person",
        "onetapAccountInput",
        "onetapAccountNumberInput",
        "onetapAccountTypeInput",
        "organisation",
        "organisationInput",
        "organisationName"]

    connect() {
        console.log("new user connect to ", this.element);

        this.updateOrganisation({ target: this.organisationInputTarget })
        this.updatePayOnAccount({ target: this.onAccountInputTarget })
        this.updateOnetapAccount({ target: this.onetapAccountInputTarget })
    }

    updateOnetapAccount(event) {
        // console.log("updateOnetapAccount", event.target.value);

        switch(event.target.value) {
            case "email":
                // hide both
                this.onetapAccountNumberInputTarget.style.display = "none";
                this.onetapAccountTypeInputTarget.style.display = "none";
                break;
            case "number":
                // hide type
                // show number
                this.onetapAccountNumberInputTarget.style.display = "block";
                this.onetapAccountTypeInputTarget.style.display = "none";
                break;
            case "create":
                this.onetapAccountNumberInputTarget.style.display = "none";
                this.onetapAccountTypeInputTarget.style.display = "block";
                break;
            default:
                // hide both
                this.onetapAccountNumberInputTarget.style.display = "none";
                this.onetapAccountTypeInputTarget.style.display = "none";
                break;
        }
    }

    updateOrganisation(event) {
        this.personTarget.style.display = !event.target.checked ? "block" : "none";
        this.organisationTarget.style.display = event.target.checked ? "block" : "none";

        this.organisationNameTarget.disabled = !event.target.checked;
    }

    updatePayOnAccount(event) {
        // console.log("updateOnlineOrders", event.target.checked);
        this.onAccountTarget.style.display = event.target.checked ? "block" : "none";
    }
}
