// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="choices">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import Choices from "choices.js"

export default class extends Controller {
  connect() {
    let options = {
      // silent: false,
      // items: [],
      // choices: [],
      // renderChoiceLimit: -1,
      // maxItemCount: -1,
      // addItems: true,
      // addItemFilter: null,
      // removeItems: true,
      // removeItemButton: false,
      // editItems: false,
      // duplicateItemsAllowed: true,
      // delimiter: ',',
      // paste: true,
      // searchEnabled: true,
      // searchChoices: true,
      // searchFloor: 1,
      // searchResultLimit: 4,
      // searchFields: ['label', 'value'],
      // position: 'auto',
      // resetScrollPosition: true,
      // shouldSort: true,
      // shouldSortItems: false,
      // sorter: () => {...},
      // placeholder: true,
      // placeholderValue: null,
      // searchPlaceholderValue: null,
      // prependValue: null,
      // appendValue: null,
      // renderSelectedChoices: 'auto',
      // loadingText: 'Loading...',
      // noResultsText: 'No results found',
      // noChoicesText: 'No choices to choose from',
      // itemSelectText: 'Press to select',
      // addItemText: (value) => {
      //   return `Press Enter to add <b>"${value}"</b>`;
      // },
      // maxItemText: (maxItemCount) => {
      //   return `Only ${maxItemCount} values can be added`;
      // },
      // valueComparer: (value1, value2) => {
      //   return value1 === value2;
      // },
      // classNames: {
      //   containerOuter: 'choices',
      //   containerInner: 'choices__inner',
      //   input: 'choices__input',
      //   inputCloned: 'choices__input--cloned',
      //   list: 'choices__list',
      //   listItems: 'choices__list--multiple',
      //   listSingle: 'choices__list--single',
      //   listDropdown: 'choices__list--dropdown',
      //   item: 'choices__item',
      //   itemSelectable: 'choices__item--selectable',
      //   itemDisabled: 'choices__item--disabled',
      //   itemChoice: 'choices__item--choice',
      //   placeholder: 'choices__placeholder',
      //   group: 'choices__group',
      //   groupHeading: 'choices__heading',
      //   button: 'choices__button',
      //   activeState: 'is-active',
      //   focusState: 'is-focused',
      //   openState: 'is-open',
      //   disabledState: 'is-disabled',
      //   highlightedState: 'is-highlighted',
      //   selectedState: 'is-selected',
      //   flippedState: 'is-flipped',
      //   loadingState: 'is-loading',
      //   noResults: 'has-no-results',
      //   noChoices: 'has-no-choices'
      // },
      // // Choices uses the great Fuse library for searching. You
      // // can find more options here: https://github.com/krisk/Fuse#options
      // fuseOptions: {
      //   include: 'score'
      // },
      // callbackOnInit: null,
      // callbackOnCreateTemplates: null
    };
    this.choices = new Choices(this.element, options);
  }
  // end(event) {
  //   let id = event.item.dataset.id;
  //   let data = new FormData();
  //   data.append('position', event.newIndex + 1);
  //   // console.log(event);
  //   // console.log("patch", this.data.get('drag-url').replace(':id', id));
  //   let url = this.data.get('url').replace(':id', id);
  //
  //   // Rails.ajax({
  //   //   url: url,
  //   //   type: 'PATCH',
  //   //   data: formData
  //   // })
  //   jQuery.ajax({
  //     url: url,
  //     type: 'PATCH',
  //     data: {'position': event.newIndex + 1}
  //   })
  // }
}
