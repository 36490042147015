import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static values = {
    url: String,
  }

  connect() {
    console.log("remote_view");
    this.getHtml();
  }

  getHtml() {
    fetch(this.urlValue)
    .then((response) => {
      if(response.ok)
        return response.text();

      console.error("Something went wrong HTTP " + response.status);
      throw new Error("Something went wrong");
    })
    .then(this.replaceContent)
    .catch(this.error)
  }

  replaceContent = (html) => {
    this.element.innerHTML = html;
  }

  error = (e) => {
    console.error("remote view", e);
    this.element.textContent = e.message;
  }
}
