import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    appropo.log("modal found");
    document.addEventListener("appropo:close-modals", this.close);
  }

  disconnect() {
    document.removeEventListener("appropo:close-modals", this.close);
  }

  close = (e) => {
    e.preventDefault();
    this.element.remove();
  }
}
