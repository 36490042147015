// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

console.log("tabs");

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "region", "map" ]
  static values = {
    lat: String,
    lng: String
  }

  _map = null
  // _circles = []
  // _markers = []

  connect() {
    console.log("delivery region controller");

    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  disconnect() {

  }

  initializeMap() {
    console.log('init');
    this.map();
    this.markers();
  }

  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          this.latValue,
          this.lngValue
        ),
        zoom: 14
      })
    }
    return this._map
  }

  markers() {


    // remove markers
    // this._circles.forEach( (circle) => {
    //   circle.setMap(null);
    // });

    // this._markers.forEach( (marker) => {
    //   marker.setMap(null);
    // });

    // this._circles = [];
    // this._markers = [];

    if(this._map == undefined)
      return;

    console.log("markers");

    // regions
    this.regionTargets.forEach( (region) => {

      // console.log("region", region);
      var lat = parseFloat($("input[name$=\"[latitude]\"]", region)[0].value);
      var lng = parseFloat($("input[name$=\"[longitude]\"]", region)[0].value);
      var radius = parseInt($("input[name$=\"[radius]\"]", region)[0].value);

      var pos = {
        lat: lat,
        lng: lng
      }

      if (region.circle == undefined)
      {
        var circle = new google.maps.Circle({
          map: this._map,
          center: pos,
          radius: radius,
          fillColor: 'lightblue',
          strokeColor: 'blue'
        });
        region.circle = circle;
      } else {
        region.circle.setCenter(pos);
        region.circle.setRadius(radius);
      }
      if (region.marker == undefined)
      {
        var marker = new google.maps.Marker({
            position: pos,
            draggable: true,
            icon: {
                url: "https://maps.gstatic.com/intl/en_us/mapfiles/markers2/measle_blue.png",
                size: new google.maps.Size(7, 7),
                anchor: new google.maps.Point(4, 4)
            },
            map: this._map
        });
        region.circle.bindTo('center', marker, 'position');

        google.maps.event.addListener(marker, 'dragend', function(evt) {
          console.log("marker moved", evt);

          $("input[name$=\"[latitude]\"]", region)[0].value = evt.latLng.lat().toFixed(7);
          $("input[name$=\"[longitude]\"]", region)[0].value = evt.latLng.lng().toFixed(7);
            // document.getElementById('current').innerHTML = '<p>Marker dropped: Current Lat: ' + evt.latLng.lat().toFixed(3) + ' Current Lng: ' + evt.latLng.lng().toFixed(3) + '</p>';
        });

        region.marker = marker;
      } else {
        region.marker.setPosition(pos);
      }

    });

  }

  changed(e) {
    console.log("changed", e);
    this.updateMap(this.regionTargets);
  }

  regionTargetConnected(element) {
    console.log("delivery region connected", element);
    this.updateMap(this.regionTargets);
  }

  regionTargetDisconnected(element) {
    console.log("delivery region connected", element);
    this.updateMap(this.regionTargets);
  }

  updateMap(regions) {
    console.log("update map", regions);
    this.markers();
  }

}
