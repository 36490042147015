import { Controller } from "@hotwired/stimulus"
import { logger } from "@rails/actioncable";
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = [ "status", "events", "orders" ]

  static values = {
    id: String,
  }

  connect() {
    console.log("store_app", this.element);
    this.statusTarget.innerHTML = "Connecting 🔵";

    this.subscription = consumer.subscriptions.create(
      {
        channel: "StoreEventsChannel",
        id: this.idValue,
      },
      {
        initialized: function() { console.log("initialized", this.identifier) },
        rejected: () => console.log("rejected"),
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    );
  }

  disconnect() {
    if(this.subscription)
      this.subscription.unsubscribe();
  }

  _connected(e) {
    console.log("_connected");
    this.statusTarget.innerHTML = "Connected 🟢";

    this.refreshOrders();
  }

  _disconnected(e) {
    console.log("_disconnected", e);
    if (e.willAttemptReconnect) {
      this.statusTarget.innerHTML = "Reconnecting 🟠";
    } else {
      this.statusTarget.innerHTML = "Disconnected 🔴";
    }
  }

  _received(data) {
    console.log("_received", data);
    this.appendEvent(data);
    this.refreshOrders();
  }

  refreshOrders() {
    fetch(`/store-app/${this.idValue}/orders_partial`).then(response => response.text()).then( html => {
      this.ordersTarget.innerHTML = html;
    }).catch(function(error) {
      console.error("order error update", error);
    })
  }

  appendEvent(data) {
    const html = this.createEvent(data);
    this.eventsTarget.insertAdjacentHTML("beforeend", html);
  }

  createEvent(data) {
    return `
      <div class="toast">
        <span class="speaker">${data["title"]}</span>
        <span class="body">${data["subtitle"]}</span>
      </div>
    `
  }
}
