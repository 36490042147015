// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

// https://github.com/jshjohnson/Choices
export default class extends Controller {
  // static targets = ["onlineOrder", "multisite", "onetap", "subdomain"]
  static targets = ["min", "max", "minMax"]
  connect() {
    console.log("option-group", this.element);

    console.log("min-max-value", this.minMaxTarget.value);
    this.updateMinMax(this.minMaxTarget.value)
  }

  minMaxChanged(e) {
    let value = e.target.value;
    console.log("changed", value);

    this.updateMinMax(value);
  }

  updateMinMax(value) {
    this.minTarget.style.display = value == "custom" ? "block" : "none";
    this.maxTarget.style.display = value == "custom" ? "block" : "none";
  }
}
