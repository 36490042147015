// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"

console.log("coupon")

// https://github.com/jshjohnson/Choices
export default class extends Controller {
  // static targets = ["max", "minMax"]
  connect() {
    console.log("coupon form", this.element);

    // console.log("min-max-value", this.minMaxTarget.value);
    // this.updateMinMax(this.minMaxTarget.value)
  }

  // minMaxChanged(e) {
  //   let value = e.target.value;
  //   console.log("changed", value);

  //   this.updateMinMax(value);
  // }

  // updateMinMax(value) {
  //   // this.minTarget.style.display = value == "custom" ? "block" : "none";
  //   this.maxTarget.style.display = value == "custom" ? "block" : "none";
  // }
}
