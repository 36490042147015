// console.log("layout");

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navigation"]

  connect() {
    // console.log("layout: ", this.element);
  }

  toggleNav(e) {
    e.preventDefault();
    // console.log("toggle nav");

    this.navigationTarget.classList.toggle("open");
  }
}
