import { Controller } from "@hotwired/stimulus"
let debounce = require("lodash/debounce");

console.log("auto-submit")

export default class extends Controller {
    initialize() {
      this.submit = debounce(this.submit, 500).bind(this)
    }

    // connect() {

    // }

    submit(e) {
      console.log("submitting");
      this.element.submit();
    }
}
