// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

console.log("blurhash");

import { Controller } from "@hotwired/stimulus"
import { decode, isBlurhashValid } from 'blurhash'

export default class extends Controller {
  connect() {
    let blurhash = this.element.dataset.hash;
    let width = +(this.element.dataset.width || 100);
    let height = +(this.element.dataset.height || 100);
    console.log('blurhash', this.element.dataset);
    console.log('valid', isBlurhashValid(blurhash));
    const pixels = decode(blurhash, width, height);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const imageData = ctx.createImageData(width, height);
    imageData.data.set(pixels);
    ctx.putImageData(imageData, 0, 0);
    this.element.append(canvas);
  }
}
