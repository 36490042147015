import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["item", "position"]
  connect() {
      // console.log("sortable", this.element);
      console.log("sortable", this.itemTargets.length);
  }

  insertChildAtIndex(element, child, index) {
    if (!index) index = 0
    if (index >= element.children.length) {
      element.appendChild(child)
    } else {
      console.log("insert", child);
      console.log("Before", element.children[index]);
      element.insertBefore(child, element.children[index])
    }
  }

  up(e) {
    e.preventDefault();
    console.log("up", e.target);

    var node = e.target.closest('*[data-sortable-target]');
    // console.log("node", node);

    var index = this.itemTargets.indexOf(node);
    var length = this.itemTargets.length;

    var oldIndex = index;
    var newIndex = index  - 1;

    console.log("" + oldIndex + " -> " + newIndex);

    if(oldIndex <= 0) {
      // do nothing
    } else {
      // this.insertChildAtIndex(this.element, node, newIndex);

      this.element.insertBefore(node, this.itemTargets[newIndex]);

      this.positionTargets.forEach((element, index) => {
        element.value = length - index;
      });
    }
  }

  down(e) {
    e.preventDefault();
    console.log("down", e.target);

    var node = e.target.closest('*[data-sortable-target]');
    // console.log("node", node);

    var index = this.itemTargets.indexOf(node);
    var length = this.itemTargets.length;

    var oldIndex = index;
    var newIndex = index + 1;

    console.log("" + oldIndex + " -> " + newIndex);

    if(oldIndex >= (length - 1)) {
      // do nothing
    } else {
      // this.insertChildAtIndex(this.element, node, newIndex);

      this.element.insertBefore(node, this.itemTargets[newIndex].nextSibling);

      this.positionTargets.forEach((element, index) => {
        element.value = length - index;
      });
    }
  }
}
