import { Controller } from "@hotwired/stimulus"

console.log("fullscreen");
export default class extends Controller {
  connect() {
    addEventListener('fullscreenchange', this.stateChanged);
  }

  open(event) {
    event.preventDefault();
    var elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullScreen) {
      elem.webkitRequestFullScreen();
    }
  }

  close(event) {
    event.preventDefault();
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  stateChanged(event) {
    if (document.fullscreenElement) {
      console.log('fullscreen mode');
      document.body.classList.add("fullscreen");
    } else {
      console.log('exit fullscreen');
      document.body.classList.remove("fullscreen");
    }
  }
}
