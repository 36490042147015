import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["onlineOrder", "multisite", "onetap", "dnero", "subdomain"]
    connect() {
        console.log("new app connect to ", this.element);

        this.onlineOrderTarget.style.display = "none";
        this.multisiteTarget.style.display = "none";
        this.onetapTargets[0].style.display = "none";
        this.onetapTargets[1].style.display = "none";
        this.dneroTargets[0].style.display = "none";
        // this.dneroTargets[1].style.display = "none";
    }

    updateBrandName(event) {
        // console.log("updateBrandName", event.target.value);

        const slugify = string => string
            .toLowerCase()
            .replace(/[^a-z0-9]+/g, '-')
            .replace(/-$/, '')
            .replace(/^-/, '');

        this.subdomainTarget.placeholder = slugify(event.target.value);
    }

    updateOnlineOrders(event) {
        // console.log("updateOnlineOrders", event.target.checked);
        this.onlineOrderTarget.style.display = event.target.checked ? "block" : "none";
    }
    updateMultisite(event) {
        // console.log("updateMultisite", event.target.checked);
        this.multisiteTarget.style.display = event.target.checked ? "block" : "none";
    }
    updatePOS(event) {
        console.log("updatePOS", event.target.value);
        this.onetapTargets[0].style.display = event.target.value == "onetap" ? "block" : "none";
        this.onetapTargets[1].style.display = event.target.value == "onetap" ? "block" : "none";

        this.dneroTargets[0].style.display = event.target.value == "dnero" ? "block" : "none";
        // this.dneroTargets[1].style.display = event.target.value ? "block" : "none";
    }
}
