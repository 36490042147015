// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="choices">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "@hotwired/stimulus"
import TomSelect from "tom-select/dist/esm/tom-select.complete"

export default class extends Controller {
  connect() {
    var config = {
      "plugins": {
        "dropdown_input": {},
      }
    };

    // console.log("tom_select", this.element.value);

    var remote_url = this.element.dataset.remoteUrl;
    if (remote_url) {
      config.valueField = 'id';
		  config.labelField = 'title';
		  config.searchField = ['title','subtitle','id'];
      config.load = function(query, callback) {
        var url = remote_url + encodeURIComponent(query);
        fetch(url).then(response => response.json())
				          .then(json => {
          					callback(json);
          				}).catch(()=>{
          					callback();
          				});
      }
    }

    var maxItems = this.element.dataset.max;
    if (maxItems) {
      // console.log("max items", maxItems);
      config.maxItems = maxItems;
    } else if(this.element.multiple) {
      // do nothing
      // console.log("multiple");
      config.allowEmptyOption = true;

      // var inputs = document.querySelectorAll("name[" + this.element.name+"]");
      // console.log("inputs", inputs);

    } else {
      config.maxItems = 1;
    }
    var title = this.element.dataset.title;
    if (title) {
      config.options = [
        {id: this.element.value, title: title}
      ]
    }
    var create = this.element.dataset.create;
    if (create) {
      config.create = create;
    }

    var remove = this.element.dataset.remove;
    if(remove) {
      config.plugins.no_backspace_delete = {}
      config.plugins.remove_button = {
        "title": 'Remove'
      }
    }

    // console.log("this.element.dataset", this.element.dataset);

    this.select = new TomSelect(this.element, config);
  }

  disconnect() {
    this.select.destroy();
  }

  // end(event) {
  //   let id = event.item.dataset.id;
  //   let data = new FormData();
  //   data.append('position', event.newIndex + 1);
  //   // console.log(event);
  //   // console.log("patch", this.data.get('drag-url').replace(':id', id));
  //   let url = this.data.get('url').replace(':id', id);
  //
  //   // Rails.ajax({
  //   //   url: url,
  //   //   type: 'PATCH',
  //   //   data: formData
  //   // })
  //   jQuery.ajax({
  //     url: url,
  //     type: 'PATCH',
  //     data: {'position': event.newIndex + 1}
  //   })
  // }
}
