// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

console.log("bulk");

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["commit", "form", "checkbox"]
  connect() {
    console.log("bulk: ", this.element);

    // var tabButtons = this.element.querySelectorAll('a[data-toggle="tab"]');
    // tabButtons.forEach((button, i) => {
    //   button.addEventListener('click', this.click.bind(this));
    // });

  }

  toggle(e) {
    e.preventDefault = true;

    console.log("toggle", e.target.checked);

    this.checkboxTargets.forEach(element => {
      element.checked = e.target.checked;
    });
  }

  submit(commit) {
    console.log("commit the form", commit)
    this.commitTarget.value = commit;
    this.formTarget.submit();
  }

  disable(e) {
    e.preventDefault = true;
    console.log("disable");

    this.submit("Disable");
  }

  enable(e) {
    e.preventDefault = true;
    console.log("enable");

    this.submit("Enable");
  }

  out_of_stock(e) {
    e.preventDefault = true;
    console.log("out_of_stock");

    this.submit("Out of Stock");
  }

  out_of_stock_today(e) {
    e.preventDefault = true;
    console.log("out_of_stock_today");

    this.submit("Out of Stock - Until Tomorrow");
  }
}
