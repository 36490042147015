// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

console.log("tabs");

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    // console.log("tabbed: ", this.element);

    var tabButtons = this.element.querySelectorAll('a[data-toggle="tab"]');
    tabButtons.forEach((button, i) => {
      button.addEventListener('click', this.click.bind(this));
    });

  }
  click(e) {
    e.preventDefault = true;
    // console.log("clicked tab", e.target);

    // deactivate tabs
    var tabItems= this.element.querySelectorAll('.tab-item');
    tabItems.forEach((item, i) => {
      item.classList.remove("active")
    });

    let tabPane = document.querySelector(e.target.hash);
    tabPane.parentNode.querySelector('.tab-pane.active').classList.remove("active");

    // activate new tab
    tabPane.classList.add("active");
    e.target.parentNode.classList.add("active");
  }
}
