import { Controller } from "@hotwired/stimulus"
import e from "trix";

export default class extends Controller {
  static targets = [ "countdown", "orders", "timers", "error", "errorIcon", "audioIcon" ]
  audio = new Audio('/alert-new.mp3')
  audioAllowed = false
  flashing = false

  static values = {
    url: String,
    refreshInterval: { type: Number, default: 20 },
  }

  countdowntoRefresh = 0
  interval = null

  connect() {
    // addEventListener('fullscreenchange', this.stateChanged);
    appropo.log("live_orders", this.element);

    if(!this.hasUrlValue) {
      console.error("data-live-url-value=\"http://example.com\" needs url value");
      return;
    }

    document.addEventListener('visibilitychange', this.visibiltyStateChanged);
    document.querySelector('body').addEventListener('click', this.touched);
    document.querySelector('body').addEventListener('ontouchstart', this.touched);
    document.addEventListener('appropo:order:new', this.trackOrder);

    this.countdowntoRefresh = this.refreshIntervalValue;
    this.start();

    this.errorIconTarget.style.display = "none";
    this.isAudioAllowed().then((allowed) => {
      this.audioAllowed = allowed;
      appropo.log("isAudioAllowed", this.audioAllowed);
      this.audioIconTarget.style.display = this.audioAllowed ? "none" : "inline-block";
    });
  }

  disconnect() {
    this.stop();
    document.removeEventListener('visibilitychange', this.visibiltyStateChanged);
    document.querySelector('body').addEventListener('click', this.touched);
    document.querySelector('body').addEventListener('ontouchstart', this.touched);
    document.removeEventListener('appropo:order:new', this.trackOrder);
  }

  start() {
    if(this.interval == null) {
      appropo.log("starting");
      this.interval = setInterval(this.tick, 1000);
    }
  }

  stop() {
    if(this.interval) {
      appropo.log("stopping");
      clearInterval(this.interval);
    }
    this.interval = null;
  }

  isAudioAllowed = () => {
    return new Promise(resolve => {
      const checkHTML5Audio = async () => {
        const audio = new Audio();
        try {
          audio.play().then( () => {
            resolve(true);
          }).catch( () => {
            resolve(false);
          })
          setTimeout(() => {
            resolve(true);
          })
        } catch (err) {
          resolve(false);
        }
      };
      try {
        checkHTML5Audio();
      } catch (e) {
        resolve(false);
      }
    });
  };

  visibiltyStateChanged = () => {
    if(document.hidden) {
      this.stop();
    } else {
      this.start();
    }
  }

  touched = (_event) => {
    if(this.flashing) {
      appropo.log("touched");
      this.flashing = false;
      document.querySelector('body').classList.remove("flash");
    }
  }

  tick = () => {
    if (this.countdowntoRefresh !== "Refreshing...") {
      this.countdowntoRefresh -= 1;
    }

    if (this.countdowntoRefresh <= 0) {
      this.countdowntoRefresh = "Refreshing...";
      this.getOrders();
    }
    this.countdownTarget.textContent = this.countdowntoRefresh;

    if(this.flashing && (this.countdowntoRefresh % 3 == 0)) {
      this.audio.play();
    }
  }

  getOrders() {
    appropo.log("replacing");
    // location.reload();

    fetch(this.urlValue)
      .then((response) => {
        if(response.ok)
          return response.text();

        throw new Error("Something went wrong HTTP " + response.status);
      })
      .then(this.replaceOrders)
      .catch(this.error)
  }

  error = (e) => {
    console.log('error', e.message);
    this.countdowntoRefresh = 10;

    this.errorIconTarget.style.display = "inline-block";
    this.errorTarget.textContent = "Error when refreshing, will retry automatically - ";
    this.timersTarget.classList.add("bg-error");
  }

  replaceOrders = (html) => {
    this.ordersTarget.innerHTML = html;

    this.ordersTarget.querySelectorAll('script').forEach((content) => {
      const script = document.createElement('script')
      script.innerHTML = content.innerHTML

      document.head.appendChild(script).parentNode.removeChild(script)
    })

    this.countdowntoRefresh = this.refreshIntervalValue + 1;

    this.errorTarget.textContent = "";
    this.errorIconTarget.style.display = "none";
    this.timersTarget.classList.remove("bg-error");
  }

  flashScreen = () => {

    if(this.flashing)
      return;

    appropo.log("ding"); //, data);

    this.flashing = true;
    // if(this.audioAllowed) {
      this.audio.play();
    // } else {
      // console.log("no audio autoplay");
    // }

    document.querySelector('body').classList.add("flash");

    // setTimeout(function() {
    //   document.querySelector('body').classList.add("flash");
    //   setTimeout(function() {
    //     document.querySelector('body').classList.remove("flash");

    //           setTimeout(function() {
    //             document.querySelector('body').classList.add("flash");
    //             setTimeout(function() {
    //               document.querySelector('body').classList.remove("flash");

    //                 setTimeout(function() {
    //                   document.querySelector('body').classList.add("flash");
    //                   setTimeout(function() {

    //                     if(document.querySelector('body').classList.contains("flash"))
    //                     {
    //                       document.querySelector('body').classList.remove("flash");
    //                       flashScreen();
    //                     }
    //                   }, 30000)
    //                 },500);

    //             }, 200)
    //           }, 500);

    //   }, 200)
    // }, 500);
  }

  trackOrder = (event) => {
    // appropo.log("track", event);

    var orderId = event.data;
    appropo.log("track", orderId)

    var orderExists = sessionStorage.getItem("order-"+orderId);
    if (orderExists) {
      // already tracked
    } else {

      var order = document.querySelector("#"+orderId);
      order.classList.add("pulse");

      // debugger;
      // ding
      this.flashScreen();
      sessionStorage.setItem("order-"+orderId, true);
    }
  }
}
